/*
========================================================================================
		eGátt side-menu
========================================================================================
 */

#side-menu {
	width: 350px;
	height: 100vh;
	position: fixed;
	top: 0px;
	right: 0px;
	background-color: $white;
	z-index:10001;
	overflow-y: scroll;
}
@media (max-width: 767.98px) {
	#side-menu {
		width: 100%;
	}
}

#side-menu-container {
	padding: 25px;
}

#side-menu-close {
	cursor: pointer;
	color: $gray-800;
	padding: 20px 40px;
	margin: -20px -40px 0px 0px;
}
#side-menu-close:hover {
	color: $gray-400;
}
#side-menu-list {
	margin-top: 50px;
	list-style-type: none;
	padding-left:0;
}
#side-menu-list li {
	margin-bottom: 10px;
}