/*
========================================================================================
		Modals
========================================================================================
 */
 
 .modal-mask {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.egatt-loading-wheel-wrapper {
	position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: #FFF; /* Fallback color */
    background-color: rgba(255,255,255,0.7); /* Black w/ opacity */
}
.egatt-loading-wheel {
	width: 250px;
	height: 235px;
	text-align: center;
	margin-top: 35vh;
	margin-left: auto;
	margin-right: auto;
	padding-top: 60px;
	background-color: $primary;
	border-radius: 15px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.egatt-loading-wheel i {
	color: #fff;
	margin-bottom: 10px;
}
.egatt-loading-wheel p {
	font-size: 120%;
	font-weight: 200;
	// text-transform: uppercase;
	color: #fff;
}