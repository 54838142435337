/* 
-------------------------------------------------------------
		search-list
------------------------------------------------------------- 
*/
.search-list-container {
	display: flex;
	flex-direction: column;
}
.search-list-item {
	&:hover {
		cursor: pointer;
		background: #bbb;
	}
	&.active {
		background: $primary;
		color: #fff;
	}
}
.refresh-icon {
	padding: 5px;
}

/* 
-------------------------------------------------------------
		medicin-search
------------------------------------------------------------- 
*/

#input-placeholder{padding: .5em;}

.select-input-container{
	position: relative;
	display: flex;
	flex-direction: column;
}

.select-container {
	position: relative;
	display: flex;
}

.input-container {
	position: absolute;
	background: #fff;
	top: 37px;
	left: 0px;
	width: 100%;
	overflow-y: scroll;
	z-index: 100000;
	max-height: 400px;
	border: 1px solid lighten($primary, 10%);
}

.input-row {
	display: flex;
	justify-content: space-between;
	padding: .5em;
	.input-field {
		margin: 0;
		width: 24%;
		word-break: break-all;
		&.input-name {
			width: 35%;
		}
		&.input-count{
			width: 14%;
			text-align: right;
		}
		&.input-str, &.input-form{ 
			text-align: center;
		}
	}
}

.input-header{background: #ccc;}

.input-values{
	cursor: pointer;
	&:hover{
		background: $primary;
		color: #fff;
	}
	&.exception{
		background: $warning;
		&:hover{
			background: $primary;
			color: #fff;
		}
	}
}

.row-container{min-width: 420px;}

.medicine-table-exception {
	background: $warning;
}

.table-borderless td,
.table-borderless th {
    border: 0;
}

/* 
-------------------------------------------------------------
		medicine-form
------------------------------------------------------------- 
*/
.non-form-info {
	background-color: theme-color-level("info", $alert-bg-level);
}
.non-form-warning {
	background-color: theme-color-level("warning", $alert-bg-level);
}

