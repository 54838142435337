/*
========================================================================================
		eGaátt Admin
========================================================================================
 */

.feature-icon {
	border-radius: 50%;
	padding: 10px;
	background-color: $light;
	color: #fff; 
}
.feature-icon-active {
	background-color: $success;
}