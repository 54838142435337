/*
========================================================================================
		Dashboard
========================================================================================
 */
 @media (max-width: 575.98px) {
	.samtengd-icons, .samtengd-break {
		display: none;
	}
 }

 @media (min-width: 576px) {
	.dashboard-icon {
		display: block;
		font-size: 4em;
	}
	.arrivals-digit {
		display: block;
		line-height: 1;
		font-size: 415%;
	}
	.balance-digit {
		display: block;
		line-height: 2;
		font-size: 200%;
	}
	.icon-lift {
		font-size: 2em;
		position: relative;
		vertical-align: top;	
		padding: 18px 5px 0px 5px;
	}
	.samtengd-icons {
		display: inline;
		font-size: 4em;
	}
}

#dashboard-doctor-cog {
	color: #fff;
	cursor: pointer;
}
#dashboard-doctor-cog:hover {
	color: $gray-400;
}
.egatt-navbar-logo {
	max-width: 90px;
}