
// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto|Source+Sans+Pro');

// Variables
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap theming functions
@import "node_modules/bootstrap/scss/functions";

// Font awesome
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";

// Vue Transitions
@import "transitions";

// Partials
@import "partials/admin";
@import "partials/login";
@import "partials/navbar";
@import "partials/sidemenu";
@import "partials/bsfixes";
@import "partials/fafixes";
@import "partials/dashboard";
@import "partials/samtengd";
@import "partials/modals";
@import "partials/lyfjagrunnur";
@import "partials/prescription";
@import "partials/tooltip";
@import "partials/rates";
@import "partials/invoice";
@import "partials/notification";
@import "partials/dropzone";

// Journal View 
@import "journalview";

@media print {
    .no-print {
        display: none !important;
    }
}
.clickable:hover {
    color: $gray-500;
    cursor: pointer;
}

.clickable-cursor:hover {
    cursor: pointer;
}

.ws-normal {
    white-space: normal;
}

.no-scroll {
    height: 100%;
    overflow: hidden;
}

.scroll-top {
    height: 1px;
}

/* for debugging */

.dbr {
    border: 2px solid red!important;
}
.dbg {
    border: 2px solid green!important;
}
.dbb {
    border: 2px solid blue!important;
}
