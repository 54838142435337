/*
========================================================================================
		Samtengd Sjúkraskrá
========================================================================================
 */

.egatt-clickable {
	cursor: pointer;
}

#no-access{
	margin-top: 20vh;
}

.label-as-badge{
	font-size: 60%;
	vertical-align: middle;
	border-radius: 1em;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);	
}

.panel-primary a:hover, .panel-primary a:active, .panel-primary a:visited, .panel-primary a:link{
  text-decoration: none;
}

.panel-primary a:hover{
  cursor: pointer;
  font-weight: bold;
  font-size: 1.05em;
}

.panel > .list-group:first-child .list-group-item:first-child, 
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child, 
.panel > .list-group:last-child .list-group-item:last-child, 
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
	border-radius: 0px;
}

.form-control {
	border-radius: 0px;
}

.slider-enter-active, .slider-leave-active {
	overflow-y: hidden;
	max-height: 2000px; /* approximate max height */

	transition-property: all;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slider-enter, .slider-leave-to {
	overflow-y: hidden;
	max-height: 0;
}
.scroller {
	max-height: 75vh;
	overflow-y: scroll;
	 
}
.last-updated {
	padding-right: 15px;
	font-size: 80%;
	color: $gray-400;
}
.textview-timestamp {
	font-size: 80%;
}
ul.entry-values {
	padding-left: 20px;
	margin-bottom: 20px;
}
ul.entry-values > li {
	list-style: none;
}

.egatt-loading-wheel-inline {
	text-align: center;
}
.egatt-loading-wheel-inline > i {
	color: $primary;
}