.autocomplete {
    position: relative;
    width: 100%;
}
.autocomplete-items {
    position: absolute;
    top: 100%;
    left: 37px;
    right: 34px;
    max-height: 400px;
    z-index: 99;
    overflow-y: scroll;
    
    background-color: $gray-100;
    border: 1px solid $gray-400;
    border-right: none;
    border-top: none;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}
.autocomplete-item {
    padding: 15px;
    border-bottom: 1px solid $gray-400;
}
.highlight {
    font-weight: 900;
    background-color: $warning;
    color: $dark;
}