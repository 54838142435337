/*
========================================================================================
		Bootstrap Fixes!
========================================================================================
 */
body {
	background-color: $body-bg;
}

.input-group-text-select .form-control {
	width: 50%;
	min-height: 2.5rem;
}
.input-group .vdp-datepicker {
	display: table-cell;
	position: relative;
	z-index: 2;
	float: left;
	height: 2.5rem;
	width: 100%;
	padding: 0px;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
	background-color: #fff;
	background-image: none;
}
.input-group .vdp-datepicker input {
	width: 100%;
	height: 2.5rem;
	border: 1px solid #ccc;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555555;
	padding-left: 10px;
}