/*
========================================================================================
		FONT-AWESOME Fixes!
========================================================================================
 */
 
 
// .fa-button-icon {
// 	line-height: 1.4;
// }
.fa-col-icon {
	line-height: 2;
	font-size: 130%;
}
.fa-panel-icon {
	text-align: center;
	width: 30px;
}
.fa-samtengd {
	margin: 14px 0px;
}