.invoice-discount {
    cursor: pointer;
}
.invoice-discount:hover > td{
    cursor: pointer;
    background-color: $primary;
    color: $white; 
}
.feedback {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
}