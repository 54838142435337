.header {
	justify-content: space-between;
	align-items: center;
	background-color: #EEF1F3;
	font-weight: 700;
	font-size: 14px;
	line-height: 16.94px;
	border-radius: 8px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.header:hover {
	z-index: 999;
	cursor: pointer;
	background-color: rgba(110, 183, 204, 0.2) !important;
}
.text-journal-small {
	font-size: 12px;
}
.text-journal-normal {
	font-size: 14px;
}
.text-journal-medium {
	font-size: 18px;
}
.text-journal-large {
	font-size: 22px;
}
.text-journal-extra-large {
	font-size: 26px;
}
.text-normal {
	font-weight: normal;
	color: black;
}
.text-bold {
	font-weight: bold;
	color: black;
}
.owner {
	color:#6EB7CC;
}
.jv-card {
	border-radius: 8px!important;
	margin: 8px 12px;
	background-color: white!important;
}
.content-margin {
	margin-left: 44px;
}
.main-bg {
	background: #F0F3F8!important;
}
.bg-transparent {
	background-color: transparent;
}
