/*
========================================================================================
        DropZone
========================================================================================
 */

.aroundDZ {
	display: flex;
	flex-direction: column;
	border:2px dashed #57A2FF; 
	border-radius: 0.5rem;
	padding:2rem; 
	text-align:center;
	margin-top:1rem;
}

.aroundDragDZ {
	display: flex;
	flex-direction: column;
	border:2px dashed #57A2FF; 
	border-radius: 0.5rem;
	padding:2rem; 
	margin-top:1rem;
	background-color: rgba(72, 190, 162, 1.0);
}

