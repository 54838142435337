
// Body
$body-bg: #fff;

// Colors
$info: #29b6f6;

// Gradients
$enable-gradients: true;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

$font-family-sans-serif: Inter, "Open Sans", sans-serif;
$font-family: Inter, "Open Sans", sans-serif;
$font-size-base: 0.9rem !default;
$line-height-base: 1.6;
