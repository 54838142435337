/*
========================================================================================
		eGaátt Notification
========================================================================================
 */

.notification-container {
    display: flex;
    justify-items: start;

    width: 20rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.8rem 2rem -1rem rgb(122, 122, 122);

    text-size-adjust: small;
    background-color: white;
    
    /* opacity: 1; */
    /* -webkit-transition: opacity 1000ms linear;
    transition: opacity 1000ms linear; */

}

.text-container {
    display: flex;
    justify-items: start;
    flex-direction: column;
    flex-grow: 2;
    height: 100%;
    margin-left: 1rem;
}

.close-button {
    display: flex;
    align-items: start;
    border: none;
    padding-left: 2rem;
    font-weight: 100;
    font-size: large;
    color: rgb(142, 142, 142);
    background-color: transparent;
}
.close-button:hover {
    cursor: pointer;
    color: black;
}

.mr-2 {
    margin-right: 0.5rem;
}

.fill-current {
    fill: currentColor;
}

.text-container h4 {
    margin-bottom: 0.2rem;
    font-size: medium;
    font-weight: 500;
    font: bold;
}

.notification-container i {
    font-size: large;
}

.body-text {
    color: rgb(70, 70, 70);
    font-weight: 400;
    font-size: 0.9rem;
}

.bell-btn {
    border: none;
    background-color: transparent;
    width: 4rem;
    justify-content: right;
    text-decoration: none;
    list-style: none;
}