/*
========================================================================================
		eGátt Login
========================================================================================
 */

#login-panel {
	margin-top: 15vh;
}
#login-panel h2{
	margin-top: -40px;
	margin-bottom: 50px;
	margin-left: 130px;
}