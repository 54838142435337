/*
========================================================================================
        Lyfjagrunnur
========================================================================================
 */

.lyfjagrunnur-button{
    background-color: $primary;
    color: #fff;
    padding: .5em .8em;
    font-size: 1em;
    border: none;
    outline: none;
    cursor: pointer;
    &.pressed{
        background-color: lighten($primary, 20%);
        color: $primary;
    }
    &:hover{
        background-color: #eee;
        color: #333;
    }
}

.lyfjagrunnur-link{
    text-decoration: none;
    color: darken($primary, 30%);
    &:hover{
        color: darken($primary, 33%);
        text-decoration: none;
    }
}