.slide-fade-fast-enter-active {
    transition: all .4s ease;
}
.slide-fade-fast-leave-active{
    transition: all .2s ease;
}
.slide-fade-fast-enter-from, .slide-fade-fast-leave-to{
    transform: translateY(-3px);
    opacity: 0;
}
.slide-fade-fast-move-from{
    transition: transform .4s;
}



.fade-fast-enter-from, .fade-fast-leave-to{
    opacity: 0;
}
.fade-fast-leave-from, .fade-fast-enter-to {
    opacity: initial;
}
.fade-fast-enter-active {
    transition: all .2s ease .2s;
}

.fade-fast-leave-active {
    transition: all .2s ease;
}



/* SLIDE IN */
.slide-in-enter-from, .slide-in-leave-to {
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 0px;
    min-height: 0px;
    padding-top: 0%;
    padding-bottom: 0%;
}
.slide-in-enter-active {
    -webkit-transition: -webkit-all .4s ease .2s;
    -moz-transition: -moz-all .4s ease .2s;
    -ms-transition: -ms-all .4s ease .2s;
    transition: all .4s ease .2s;
}
.slide-in-leave-active {
    -webkit-transition: -webkit-all .2s ease;
    -moz-transition: -moz-all .2s ease;
    -ms-transition: -ms-all .2s ease;
    transition: all .2s ease;
}
.slide-in-enter-to, .slide-in-leave-from {
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 960px;
}


/* BLINK */
.blink-enter-from {
    background-color:  $primary;
    color: #fff;
    font-weight: 600;
    webkit-box-shadow: 0 0 0 0.2rem rgba($input-focus-color, 0.25);
    box-shadow: 0 0 0 0.2rem rgba($input-focus-color, 0.25);
}
.blink-enter-to {
    background-color: initial;
    color: initial;
    font-weight: initial;
}
.blink-leave-from {
    background-color: $danger;
    color: $danger;
    webkit-box-shadow: 0 0 0 0.2rem rgba($form-feedback-invalid-color, 0.25);
    box-shadow: 0 0 0 0.2rem rgba($form-feedback-invalid-color, 0.25);
}
.blink-leave-to {
    -webkit-transition: -webkit-all .2s ease-out;
    -moz-transition: -moz-all .2s ease-out;
    -ms-transition: -ms-all .2s ease-out;
    transition: all .2s ease-out;

}
.blink-enter-active {
    -webkit-transition: -webkit-all .5s ease-out;
    -moz-transition: -moz-all .5s ease-out;
    -ms-transition: -ms-all .5s ease-out;
    transition: all .5s ease-out;
}
